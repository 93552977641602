/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    a: "a",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Idea"), "\n", React.createElement(_components.p, null, "An email subscription service that helps you read long form publications\nby sending you small snippets each day, or at some other regular interval."), "\n", React.createElement(_components.p, null, "The idea came about from thinking about how quite a few humans only consume\nsmall chunks of text and less and less read long format books or magazines.\nAnother motivation was the desire to read more of the “classic” works, but\nrealizing that many of them are very dry and it could help make them\neasier to consume if broken up into more manageable chunks."), "\n", React.createElement(_components.h1, null, "Implementation"), "\n", React.createElement(_components.p, null, "A web service would consume the ASCII version of books from\n", React.createElement(_components.a, {
    href: "https://archive.org/details/gutenberg"
  }, "Project Gutenberg"), " and store them\nin a db or just on a filesystem."), "\n", React.createElement(_components.p, null, "A web form allows people to enter their email address and select a book to read.\nPerhaps you can add multiple books to read multiple books at the same time.\nThere will be a relation of email address to book subscription which\nwould keep track of that user’s offsets into the books’s text files."), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "Update"), " (2017-Feb-26): An implementation of this idea was thrown together at\nan impromptu hackathon with some friends, it is no longer hosted but the code\nis here: ", React.createElement(_components.a, {
    href: "https://github.com/dvingo/guten_better"
  }, "https://github.com/dvingo/guten_better")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
